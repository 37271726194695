import React, { useContext } from 'react';
import {
  useParams, useHistory,
} from 'react-router-dom';

import urlHash from '../../utils/functions/urlHash';

import KpiCreate from '../../components/KpiCreate';
import { AuthContext } from '../../context/AuthProvider';
import useKpiData from '../../hooks/useKpiData';

export default function KpiCreatePage() {
  const history = useHistory();
  const { hash } = useParams();

  const { currentUser } = useContext(AuthContext);
  const [kpiDataAPI] = useKpiData(currentUser);
  const getParams = () => {
    const str = urlHash(hash, true).split('/');
    return { dashboardKey: str[0], kpiKey: str[1] };
  };
  const { dashboardKey, kpiKey } = getParams();
  const handleDynamicKpi = (body, kpiFile, extra = {}) => {
    if (body.kpi_type === 'SubsetTable') {
      return kpiDataAPI.genSubsetData(
        body, kpiFile, extra.page ?? 0, extra.sortBy ?? '', extra.sortOrder ?? 'asc',
      );
    }
    return kpiDataAPI.genKpiData(body, kpiFile);
  };

  return (
    <>
      <KpiCreate
        dashboardKey={dashboardKey}
        goBack={() => history.goBack()}
        handleDynamicKpi={handleDynamicKpi}
        kpiKey={kpiKey}
        history={history}
      />
    </>
  );
}
