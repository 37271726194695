/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveWaffle } from '@nivo/waffle';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import { dataSort, genData1D } from '../../utils/formatCharts/handleData';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Waffle = ({
  data, styleConfig, globalTheme,
}) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genData1D(data, styleConfig));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  const createTooltip = (val) => customTooltip(val.data, 'waffle', styleConfig);

  return chartData.ready && data?.index?.[0] !== 'Totais' ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
      ])}
    >
      <ResponsiveWaffle
        data={chartData.data}
        total={chartData.total}
        /* General */
        rows={+(styleConfig?.RowsControl || 15)}
        columns={+(styleConfig?.ColumnsControl || 10)}
        margin={styleConfig?.Margin || {
          top: 40, right: 60, bottom: 40, left: 60,
        }}
        fillDirection={styleConfig?.FillDirection}
        padding={+(styleConfig?.PaddingControl?.value)}
        /* Style */
        borderWidth={+(styleConfig?.BorderWidthControl?.value || 0)}
        borderColor={styleConfig.BorderColorControl ? styleConfig.BorderColorControl : {
          from: 'color', modifiers: [['darker', 1.6]],
        }}
        colors={colorSelector({
          dataLength: chartData.data?.length,
          config: styleConfig,
          colorKey: 'id',
          globalTheme,
        })}
        /* Legend */
        legends={styleConfig?.LegendControl?.checked ? [{
          ...styleConfig?.LegendPlacementControl?.value,
          itemTextColor: '#999',
          itemWidth: 100,
          itemHeight: 18,
          symbolSize: 18,
          symbolShape: styleConfig?.LegendSymbolControl || 'circle',
          itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
          justify: false,
          effects: [{
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          }],
        }] : []}
        /* Tooltip */
        isInteractive={!!styleConfig?.InteractiveControl}
        tooltip={createTooltip}
        /* Extra theme */
        theme={{
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
        }}
        /* Static */
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        emptyOpacity={0}
      />
    </KpiStatus>
  ) : <></>;
};

Waffle.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

Waffle.defaultProps = {
  globalTheme: {},
};

export default Waffle;
