import styled, { css } from 'styled-components';

const types = (type, theme) => {
  switch (type) {
    case 'category':
      return theme.info;
    case 'datetime64[ns]':
      return theme.warning;
    case 'float64':
      return theme.success;
    default:
      return '';
  }
};

export const MainContainer = styled.div`
  padding: 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.fadedBackground};
  z-index: 10;
`;

export const GridContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr minmax(0, 30vw);
  grid-gap: 1rem;
  height: calc(100vh - 340px);
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const LeftSide = styled(Side)`
  width: 260px;
  height: 100%;
  overflow: hidden;
`;

export const RightSide = styled(Side)`
  padding: .5rem;
  align-items: flex-start;
  align-content: flex-start;
  background-color: ${({ theme }) => theme.card};
  overflow-y: auto;
  & > div {
    width: 100%;
    cursor: default;
  }
  & > * {
    margin-bottom: 10px;
  }
`;

export const EmptyMsg = styled.span`
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  color: ${({ theme }) => theme.grey};
`;

export const FullMsg = styled.span`
  text-align: center;
  margin-top: -.8rem;
  width: 100%;
  color: ${({ theme }) => theme.error};
  font-size: 12px;
  font-style: italic;
`;

export const InfoTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 500px;
  white-space: wrap;
`;

export const ColumnBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid;
  padding: .2rem;
  border-radius: .5rem;
  width: 100%;
  box-sizing: border-box;
  ${({ theme, varType }) => css`
    border-color: ${types(varType, theme)};
    color: ${types(varType, theme)};
    background-color: ${types(varType, theme)}20;
    & div > svg {
      fill: ${types(varType, theme)};
      margin-right: 10px;
    }
  `};
  & > div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  & div > button svg {
    width: 12px;
    height: 12px;
  }
  & h5 {
    font-size: 16px;
    margin: 0;
    width: calc(100% - 80px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .values-list {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    & span {
      margin-left: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 5px;
`;

export const TabItem = styled.button`
  background-color: ${({ theme }) => theme.containerOdd};
  color: ${({ theme }) => theme.primary};
  padding: 5px;
  border: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  width: 100%;
  font-weight: bold;
  height: 30px;
  cursor: pointer;
  ${({ theme, active }) => (active ? css`
    background-color: ${theme.primary};
    color: ${theme.white};
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
    }
  `)}
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 4px;
  box-sizing: border-box;
  & > * + * {
    margin-top: 10px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > div {
    overflow: hidden;
  }
  & > * + * {
    margin-left: 5px;
  }
`;
