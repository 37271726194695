/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import customAxis from '../../utils/formatCharts/formatAxis';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';
import { dataSortPoints, genData2DWithPoints } from '../../utils/formatCharts/handleData';
import { getBiggestTick, getSmallestTick } from '../../utils/formatCharts/getAxisTicks';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Line = ({
  data, styleConfig, isEdit, globalTheme, handleDrilldownClick,
}) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genData2DWithPoints(data, styleConfig, true));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSortPoints(chartData.data, styleConfig, true),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  const biggestTick = useMemo(() => (
    getBiggestTick(chartData.biggest, styleConfig)
  ), [chartData]);
  const smallestTick = useMemo(() => (
    getSmallestTick(chartData.smallest, styleConfig)
  ), [chartData]);

  const createLabel = (label) => customValueLabel(label.y, styleConfig, label.x);

  const createTooltip = (val) => customTooltip(val, 'line', styleConfig, handleDrilldownClick);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
      ])}
    >
      <ResponsiveLine
        data={chartData.data}
        /* GENERAL */
        onClick={handleDrilldownClick ? ((dataPoint) => {
          handleDrilldownClick(dataPoint?.points[0]?.data?.id);
        }) : null}
        useMesh={styleConfig.InteractiveControl ?? true}
        lineWidth={styleConfig.LineWidth}
        margin={
          styleConfig?.Margin || {
            top: 50,
            right: 130,
            bottom: 50,
            left: 60,
          }
        }
        /* POINTS */
        enablePoints={styleConfig?.EnablePointsControl?.checked ?? false}
        pointSize={styleConfig?.EnablePointsControl?.pointsSize || 10}
        pointBorderWidth={styleConfig?.EnablePointsControl?.border || 2}
        pointColor={styleConfig?.PointsColorControl || { theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        enableGridX={styleConfig?.EnableGridX ?? false}
        enableGridY={styleConfig?.EnableGridY ?? false}
        /* AREA */
        areaOpacity={styleConfig?.AreaControl?.opacity || 0.2}
        enableArea={styleConfig?.AreaControl?.checked || false}
        curve={styleConfig?.LinesFormatControl || 'linear'}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        /* LABEL */
        enablePointLabel={styleConfig?.EnableLabelControl?.checked ?? false}
        pointLabel={createLabel}
        labelSkipWidth={12}
        labelSkipHeight={12}
        /* TOOLTIP */
        isInteractive={styleConfig.InteractiveControl}
        sliceTooltip={({ slice }) => slice.points.map(createTooltip)}
        enableSlices="x"
        /* AXIS */
        axisTop={customAxis('top', styleConfig, false)}
        axisLeft={customAxis('left', styleConfig)}
        axisBottom={customAxis('bottom', styleConfig, false)}
        axisRight={customAxis('right', styleConfig)}
        legends={styleConfig?.LegendControl?.checked ? [{
          ...styleConfig?.LegendPlacementControl?.value,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 18,
          symbolShape: styleConfig?.LegendSymbolControl || 'circle',
          itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
          effects: [{ on: 'hover', style: { itemTextColor: '#000' } }],
          toggleSerie: !isEdit,
        }] : []}
        yScale={{
          type: 'linear',
          stacked: false,
          min: styleConfig?.MinValueControl?.checked
            ? smallestTick
            : styleConfig?.MinValueControl?.value || 0,
          max: styleConfig?.MaxValueControl?.checked
            ? biggestTick
            : styleConfig?.MaxValueControl?.value || 0,
        }}
        /* STYLES/THEMES */
        colors={colorSelector({
          dataLength: chartData.data.length,
          config: styleConfig,
          colorKey: 'id',
          globalTheme,
        })}
        theme={{
          axis: {
            legend: {
              text: {
                fontSize: styleConfig?.AxisGlobal?.legendFontSize,
                fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
                fill: styleConfig?.AxisGlobal?.color,
              },
            },
            ticks: {
              text: {
                fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
                fontSize: styleConfig?.AxisGlobal?.fontSize,
                fill: styleConfig?.AxisGlobal?.color,
              },
            },
          },
          dots: {
            text: {
              fontFamily: `'${styleConfig?.DotsLabel?.fontFamily}', Arial`,
              fontSize: styleConfig?.DotsLabel?.fontSize,
              fill: styleConfig?.DotsLabel?.color,
              transform: `translate(${styleConfig?.LabelTranslateX ? styleConfig?.LabelTranslateX : 0}px, ${styleConfig?.LabelTranslateY ? styleConfig?.LabelTranslateY : 0}px) rotate(${styleConfig?.LabelRotationControl ? styleConfig?.LabelRotationControl : 0}deg)`,
              transformOrigin: 'center center',
              transformBox: 'fill-box',
            },
          },
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
        }}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
        markers={styleConfig.ShowMarkerControl
          ? styleConfig.MarkersList?.reduce((aux, lines) => {
            if (lines.showLine) {
              aux.push({
                ...lines,
                textStyle: {
                  ...lines.textStyle,
                  fontFamily: `'${lines.textStyle.fontFamily}', Arial`,
                },
              });
            }
            return aux;
          }, []) : []}
      />
    </KpiStatus>
  ) : <></>;
};

Line.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
  handleDrilldownClick: PropTypes.func,
};

Line.defaultProps = {
  globalTheme: {},
  handleDrilldownClick: null,
};

export default Line;
