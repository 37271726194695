/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { ResponsiveRadar } from '@nivo/radar';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import CustomLegend from '../../utils/chartTools/createLegend';
import { dataSort, genData2DWithKeys } from '../../utils/formatCharts/handleData';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Radar = ({
  data, styleConfig, isEdit, globalTheme,
}) => {
  const [chartData, setChartData] = useState({ ready: false });
  const [filteredDataKeys, setFilteredDataKeys] = useState([]);
  const [hiddenKeys, setHiddenKeys] = useState([]);

  useEffect(() => {
    setChartData(genData2DWithKeys(data, styleConfig));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setFilteredDataKeys(chartData.keys.filter((k) => !hiddenKeys.includes(k)));
    }
  }, [chartData, hiddenKeys]);

  const createLabel = (label) => customValueLabel(label.value, styleConfig);

  const createTooltip = (val) => customTooltip(val, 'radar', styleConfig);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
        [
          'hasNegativeValues',
          chartData.hasNegative,
        ],
      ])}
    >
      <ResponsiveRadar
        data={chartData.data}
        keys={filteredDataKeys}
        indexBy="label"
        /* GENERAL */
        maxValue="auto"
        margin={styleConfig?.Margin || {
          top: 50,
          right: 130,
          bottom: 50,
          left: 60,
        }}
        /* BORDER */
        borderWidth={styleConfig?.BorderWidthControl ? styleConfig?.BorderWidthControl.value : 0}
        borderColor={{ from: 'color', modifiers: [] }}
        /* TOOLTIP */
        isInteractive={styleConfig.InteractiveControl}
        sliceTooltip={createTooltip}
        /* DOTS / LABEL */
        enableDots={styleConfig?.DotControl}
        enableDotLabel={styleConfig?.DotLabelControl}
        dotLabel={createLabel}
        dotBorderWidth={styleConfig?.DotBorderWidthControl}
        dotLabelYOffset={styleConfig?.DotLabelYOffsetControl?.value}
        dotSize={styleConfig?.DotSizeControl?.value}
        dotColor={styleConfig?.DotPointsColorControl || '#000'}
        dotBorderColor={{ from: 'color' }}
        curve={styleConfig?.CurveControl}
        fillOpacity={styleConfig?.FillOpacityControl?.value}
        gridLevels={styleConfig?.GridLevelControl?.value}
        gridShape={styleConfig?.GridShapeControl}
        gridLabelOffset={+styleConfig?.GridLabelOffsetControl?.value}
        /* LEGEND */
        layers={['grid', 'layers', 'slices', 'dots', 'legends', (dt) => (
          styleConfig?.LegendControl?.checked ? (
            <CustomLegend
              chartDataAr={chartData.keys}
              colorParts={dt.colorByKey}
              sizes={{
                width: 2 * dt.centerX
                + (styleConfig?.Margin?.left || 0)
                + (styleConfig?.Margin?.right || 0),
                height: 2 * dt.centerY
                + (styleConfig?.Margin?.top || 0)
                + (styleConfig?.Margin?.bottom || 0),
              }}
              config={styleConfig}
              setToggleIds={setHiddenKeys}
              isEdit={isEdit}
            />
          ) : <></>
        )]}
        /* STYLES/THEMES */
        colors={colorSelector({
          dataLength: chartData.keys.length,
          config: styleConfig,
          colorKey: 'key',
          globalTheme,
        })}
        theme={{
          dots: {
            text: {
              fontSize: parseInt(styleConfig?.LabelsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LabelsFontFamilyControl?.value}', Arial`,
              transform: `translate(${styleConfig?.LabelTranslateX ? styleConfig?.LabelTranslateX : 0}px, ${styleConfig?.LabelTranslateY ? styleConfig?.LabelTranslateY : 0}px) rotate(${styleConfig?.LabelRotationControl ? styleConfig?.LabelRotationControl : 0}deg)`,
              transformOrigin: 'center center',
              transformBox: 'fill-box',
              fill: styleConfig?.DotColorValue || '#000000',
            },
          },
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
        }}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
        blendMode="multiply"
      />
    </KpiStatus>
  ) : <></>;
};

Radar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

Radar.defaultProps = {
  globalTheme: {},
};

export default Radar;
