/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveCalendar } from '@nivo/calendar';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import { ptbrMonths } from '../../../utils/formatCharts/orderDatetime';
import { genData1DCalendar } from '../../utils/formatCharts/handleData';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Calendar = ({
  data, styleConfig, globalTheme,
}) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genData1DCalendar(data, styleConfig));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  const createTooltip = (val) => customTooltip(val, 'calendar', styleConfig);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'needsDateFormat',
          !chartData.data?.length,
        ],
      ])}
    >
      <ResponsiveCalendar
        data={chartData.data}
        // GENERAL
        from={`${chartData.data[0].day} 00:00`}
        to={`${chartData.data[chartData.data.length - 1].day} 00:00`}
        align="center"
        margin={
          styleConfig?.Margin || {
            top: 30,
            right: 30,
            bottom: 110,
            left: 30,
          }
        }
        direction={styleConfig?.LayoutControl}
        yearSpacing={parseInt(styleConfig?.YearSpacingControl, 10)}
        monthSpacing={parseInt(styleConfig?.MonthSpacingControl, 10)}
        daySpacing={parseInt(styleConfig?.DaySpacingControl, 10)}
        minValue={styleConfig?.MinValueControl?.checked ? 'auto' : styleConfig?.MinValueControl?.value}
        maxValue={styleConfig?.MaxValueControl?.checked ? 'auto' : styleConfig?.MaxValueControl?.value}
        // STYLES/THEMES
        monthBorderWidth={styleConfig?.MonthWidthControl}
        monthBorderColor={styleConfig?.MonthColorControl}
        dayBorderWidth={styleConfig?.DayWidthControl}
        dayBorderColor={styleConfig?.DayColorControl}
        emptyColor={styleConfig?.EmptyColorControl}
        colors={colorSelector({
          dataLength: Math.min(chartData.data.length, parseInt(styleConfig.QtdColorsControl, 10)),
          config: {
            UseGlobalColor: styleConfig.UseGlobalColor,
            GradientPickerControl: { ...styleConfig?.GradientPickerControl },
            PalletsControl: 'gradient',
          },
          globalTheme,
        })}
        theme={{
          labels: {
            text: {
              fontSize: parseInt(styleConfig?.LabelsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LabelsFontFamilyControl?.value}', Arial`,
              fill: styleConfig?.LabelTextColorControl,
            },
          },
          legends: {
            text: {
              fontSize: parseInt(styleConfig?.LegendsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
        }}
        // LABELS
        monthLegend={(_year, month) => (styleConfig?.EnableLabelControl?.checked ? ptbrMonths[month] : '')}
        yearLegend={(year) => (styleConfig?.EnableLabelControl?.checked ? year : '')}
        yearLegendPosition={styleConfig?.YearPositionControl}
        yearLegendOffset={parseInt(styleConfig?.YearOffsetControl, 10)}
        monthLegendPosition={styleConfig?.MonthPositionControl}
        monthLegendOffset={parseInt(styleConfig?.MonthOffsetControl, 10)}
        // LEGEND
        legendFormat={(value) => (styleConfig?.DataFormat?.type === 'percentual' ? `${value}%` : value)}
        legends={styleConfig?.LegendControl?.checked ? [{
          ...styleConfig?.LegendPlacementControl?.value,
          itemWidth: 100,
          itemHeight: 18,
          symbolSize: 18,
          itemTextColor: '#999',
          symbolShape: styleConfig?.LegendSymbolControl || 'circle',
          itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
          itemCount: Math.min(
            chartData.data.length, parseInt(styleConfig.QtdColorsControl, 10) - 1,
          ),
          justify: false,
          effects: [{
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          }],
        }] : []}
        // TOOLTIPS
        isInteractive={styleConfig?.InteractiveControl}
        tooltip={createTooltip}
        // ETC
        animate={false}
      />
    </KpiStatus>
  ) : <></>;
};

Calendar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

Calendar.defaultProps = {
  globalTheme: {},
};

export default Calendar;
