import styled from 'styled-components';

export const StyledEmptyKpiCard = styled.div`
  padding: 0 10px;
  color: ${({ theme }) => theme.black};
  text-shadow: ${({ theme }) => `
    -1px -1px 1px ${theme.white}, 1px -1px 1px ${theme.white}, -1px 1px 1px ${theme.white}, 1px 1px 1px ${theme.white}
  `};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  & h2 {
    text-align: center;
    font-weight: bold;
  }
  & p {
    font-size: 14px;
    text-align: center
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2px;
  flex: 1;
  white-space: nowrap;
`;

export const StyledLoadContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
