import styled, { css } from 'styled-components';
import { devices } from '../../../../core/breakpoints';

export const StyledContainer = styled.div`
  ${(props) => (props.disabled ? css`
    background-color: transparent;
    opacity: 1;
    background-image: url(${props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 70vh;
  ` : css`
    opacity: 1;
  `)};
`;

export const KpiCreateToolbarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;

  // input para a baixo
  @media ${devices.tablet} {
    padding: 10px 20px;
  };
`;

export const LeftKpiToolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  span {
    font-size: 24px;
  };

  @media ${devices.tablet} {
    span {
      font-size: 20px;
    };
  };

  @media ${devices.phoneS} {
    span {
      font-size: 18px;
    };
  };
`;

export const StyledGridContainerCreateKpi = styled.div`
  max-height: calc(100vh - 124px);
  margin: 0.5vw;
  display: grid;
  grid-template-columns: 345px auto;
  grid-template-rows: 70px 1fr;
  grid-gap: 10px;
  grid-template-areas: 
    "fileInfo kpiType"
    "sideBar main";

  @media ${devices.phoneS}, ${devices.phoneL} {
    display: flex;
    flex-direction: column;

    .fileInfo {
      margin: 5px 0px;
    }
    .kpiType {
      margin-bottom: 5px;
    }
    .sideBar {
      margin-bottom: 5px;
    }
  }

  .middleDiv{
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    width: 100%;
  }
  .fileInfo {
    grid-area: fileInfo;
    display: flex;
    border-radius: 4px;
  }
  .sideBar {
    grid-area: sideBar;
    display: flex;
    flex-direction: column;
  }
  .kpiType {
    grid-area: kpiType;
    padding: 0px 1%;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
  }
  .main {
    grid-area: main;
    display: flex;
    justify-content: center;
    min-width: 0px;
    border-radius: 4px;
    max-height: calc(100vh - 185px);
    background-color: transparent;

    & > .chart {
      overflow-y: hidden;
    }

    & > div {
      overflow-y: ${(props) => (props.chartType !== 'Table' ? 'auto' : 'hidden')};
    }
  }
`;

export const StyledGroupSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;

  & > span:first-child {
    font-size: 0.85rem;
    color: #696969;
    font-weight: bold;
    position: ${(props) => (props.join ? 'relative' : 'absolute')};
    top: ${(props) => (props.join ? '0px' : '-5px')};
    z-index: 1;
  }

  & > div {
    width: 100%;
    height: ${(props) => (props.join ? '35px' : '')}
  }
    & > div > div:nth-child(3) {
      z-index: 999 !important;
      background-color: white;
    }

    & > div > div > div {
    }

    & > div > div > div > div {
      top: 60% !important;
    }
`;

export const StyledContainerDoubleSelect = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  & > div:first-child {
    margin-bottom: 10px;
  }

  &:nth-child(1) {
    border-left: 5px solid lightcoral;
  }

  &:nth-child(3) {
    border-left: 5px solid lightcoral;
  }

  &:nth-child(5) {
    border-left: 5px solid lightcoral;
  }
`;

export const StyledContentTab = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 335px);
  padding: 5px 3px;
  overflow: auto;
`;

export const StyledContainerButtonActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5px;
  margin-bottom: 10px;

  & > button {
    min-width: 0px;
    align-items: center;

    & svg {
      margin-left: auto;
      fill: white;
    }
  }
`;

export const StyledButtonTab = styled.button`
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  justify-self: center;
  border-style: none;
  background-color: transparent;
  opacity: 0.6;

  ${(props) => props.selected && css`
      opacity: 1;
    & > div {
      height: 5px;
      width: 100%;
      background-color: ${({ theme }) => theme.secondary};
      position: absolute;
      bottom: -9px;
    }
  `}

  &:disabled {
    opacity: 0.2;
    background-color: transparent;
    pointer-events: none;
  }

  &:active {
    & > div {
      height: 5px;
      width: 100%;
      background-color: ${({ theme }) => theme.secondary};
      position: absolute;
      bottom: -9px;
    }
  }

  &:hover {
    & > div {
      height: 5px;
      width: 100%;
      background-color: ${({ theme }) => theme.secondaryDark};
      position: absolute;
      bottom: -9px;
    }
  }

  & svg {
    height: 35px;
    width: 35px;
    pointer-events: none;
  }
`;

export const StyledControlTableMemo = styled.div`
  & > div:first-child {
    overflow: inherit;
  }
`;

const types = {
  category: { fill: '#343494' },
  object: { fill: '#343494' },
  'datetime64[ns]': { fill: 'green' },
  int64: { fill: '#bb3434' },
  float64: { fill: '#bb3434' },
  '???': { color: 'pink', 'background-color': 'lightpink', border: '1px solid pink' },
  getTypes(type) {
    return this[type] || this['???'];
  },
};

export const StyledContainerFiltered = styled.div`
  position: relative; 
  padding: 5px 0px;
  border-radius: 4px;
  margin-top: ${(props) => (props.groupName ? '0px' : '10px')};
  

  ${(props) => (props.group ? css`
  background-color: #f5f5f5; 
  ` : css`
    background-color: #f5f5f5; 
    &:nth-of-type(odd) {
      background-color: white;
    }
  `)}


  & > span {
    font-weight: bold;
    padding-left: 10px;
  }

  & > div {
    padding: 3px 10px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledDivider = styled.hr`
  width: 75%;

  &:last-child {
    display: none;
  }
`;

export const StyledChip = styled.span`
  padding: 5px;
  margin: 5px 5px 0px 0px;
  font-weight: bold;
  font-size: 0.8rem;
  max-width: 9.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  background-color: white;
  border-radius: 4px;
  white-space: nowrap;
`;

export const StyledGroupedContainer = styled.div`

`;

export const StyledGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid ${({ theme }) => theme.primary};
    background: transparent;
    padding: 10px 15px 5px;
    cursor: pointer;

  & span {
    color: ${({ theme }) => theme.primary};
    font-size: 1rem;
    font-weight: bold;
    pointer-events: none;
    text-transform: capitalize;
  }

  & > svg {
    transform: rotate(-90deg);
    width: 16px;
    height: 16px;
    transition: transform 0.5s ease-in-out;
    
    ${(props) => props.open && (
    css`
        transform: rotate(90deg);
      `
  )}
  }

  & > svg > path {
    fill: ${({ theme }) => theme.primary};
  }
`;

export const StyledGroupContent = styled.div`
  border-top: none;
  opacity: ${(props) => (props.open ? '1' : '0')};
  max-height: ${(props) => (props.open ? '100%' : '0')};
  overflow: hidden;
  transition: all 0.3s;
  margin-bottom: ${(props) => (props.open ? '10px' : '0px')};
  background-color: #fff;

  p {
    margin: 0;
  }
`;

export const StyledTypeData = styled.div`
  display: flex;
  align-items: center;

  & > svg:first-child {
    margin-right: 5px;
    padding: 0px 4px;
    width: 35px;
    height: 20px;

    ${(props) => css`
      fill: ${types.getTypes(props.type).fill};

      & > path {
        ${props.type === 'int64' || props.type === 'float64' ? 'fill: #bb3434' : ''};
        ${props.type === 'category' || props.type === 'object' ? 'fill: #343494' : ''};
      }
    `}
  }
`;

export const LabelSmall = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  margin: 0;
  font-size: 14px;
`;

export const OuterKpiCreate = styled.main`
    @page {
       size: auto;
       margin: auto;
    }

    overflow-y: auto; 
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto;
    padding: 5px 0;
    margin-top: 0rem;
    margin-left: 5%;
    margin-right: 5%;

    height: calc(100vh - 155px) !important;
    overflow: hidden;
    @media ${devices.tablet} {
      margin: 0px 0px;
      height: calc(100vh - 170px) !important;
    };

    @media ${devices.phoneL} {
      margin: 0px 0px;
      height: calc(100vh - 150px) !important;
    }
`;

export const LegendsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 10px 0;
  & .legend-type { 
    display: flex;
    gap: 5px;
    align-items: center;
  };

  & .square { 
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }
  
  & .legend-texto { 
    background: ${({ theme }) => theme.typeText};
  }

  & .legend-numero { 
    background: ${({ theme }) => theme.typeNumber};
  }

  & .legend-date {
    background: ${({ theme }) => theme.typeDate};
  }
`;

export const CustomTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 1px ${({ theme }) => theme.grey}80;
  & span, strong {
    margin: 0;
  }
`;

export const ColorLabelSymbol = styled.div`
  background-color: ${(props) => props?.color || 'black'};
  height: 15px;
  width: 15px;
  margin-right: 5px;
`;

export const TooltipColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const TooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const DrilldownTip = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.grey};
  width: 100%;
  text-align: center;
`;
