import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/SelectNew';
import Button from '../../../juristec-ui/core/Button';
import Loading from '../../../juristec-ui/core/MiniLoading';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';

import uuidv4 from '../../../juristec-ui/utils/functions/randomUUID';
import { dateOptions, filterTypeOptionsSimple } from '../../../options';

import {
  MainContainer,
  GridContainer,
  LeftSide,
  RightSide,
  ColumnBadge,
  EmptyMsg,
  LoadingContainer,
  InfoTooltip,
  FullMsg,
  TabContainer,
  TabItem,
  ScrollContainer,
  InputRow,
} from './styled/GlobalFiltersColumns.styled';

import {
  Close, DateIcon, Edit, Letters, Number,
} from '../../../juristec-ui/icons';

const op = {
  category: <Letters />,
  float64: <Number />,
  'datetime64[ns]': <DateIcon />,
};

const EMPTYSELECTION = { label: '', value: '' };

const columnIsDate = (selectedColumn) => (
  ['datetime64[ns]', 'date'].includes(selectedColumn?.type)
);

const GlobalFiltersColumns = ({
  usedFiles,
  getFileColumns,
  hide,
  globalFiltersState,
  globalFilterLimitsState,
  showToolbar,
  getUnique,
  submit,
}) => {
  const [tab, setTab] = useState('VARS');
  const [isLoading, setIsLoading] = useState(true);
  const [globalFilterSelectors, setGlobalFilterSelectors] = globalFiltersState;
  const [tempFilters, setTempFilters] = useState({});
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [columnsOpts, setColumnsOpts] = useState([]);
  const [selectedFile, setSelectedFile] = useState(EMPTYSELECTION);
  const [selectedColumn, setSelectedColumn] = useState(EMPTYSELECTION);
  //
  const [globalFilterLimits, setGlobalFilterLimits] = globalFilterLimitsState;
  const [valueOpts, setValueOpts] = useState([]);
  const [tempLimits, setTempLimits] = useState({});
  const [dateType, setDateType] = useState(EMPTYSELECTION);
  const [condition, setCondition] = useState(filterTypeOptionsSimple[0]);
  const [selectedValues, setSelectedValues] = useState([]);

  const getColumnsOpts = async (fileObj) => {
    if (fileObj.columns) {
      setColumnsOpts(fileObj.columns);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const opts = await getFileColumns(fileObj.value);
      setColumnsOpts(opts);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (usedFiles?.length > 0) {
      const fFiles = usedFiles.filter((uf) => uf.allowed);
      setSelectedFile(fFiles[0] || EMPTYSELECTION);
      getColumnsOpts(fFiles[0]);
      setFilteredFiles(fFiles);
      setTempFilters(globalFilterSelectors);
      setTempLimits(globalFilterLimits);
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleFileSelection = (selected) => {
    setSelectedFile(selected);
    setSelectedColumn(EMPTYSELECTION);
    getColumnsOpts(selected);
  };

  const changeTab = (tabKey) => {
    setTab(tabKey);
    setSelectedColumn(EMPTYSELECTION);
    setSelectedValues([]);
  };
  const setVarsTab = () => changeTab('VARS');
  const setLimitsTab = () => changeTab('LIMITS');

  const addTempFilter = () => {
    setTempFilters((gfilters) => ({
      ...gfilters,
      [uuidv4()]: {
        database: selectedFile.value,
        filename: selectedFile.label,
        column: selectedColumn.value,
        type: selectedColumn.type,
        isOpen: false,
      },
    }));
    setSelectedColumn(EMPTYSELECTION);
  };

  const removeTempFilter = (e, fId) => {
    e.preventDefault();
    e.stopPropagation();
    setTempFilters((gfilters) => {
      const { [fId]: _, ...auxObj } = gfilters;
      return auxObj;
    });
  };

  const getValueOpts = async (fileId, columnName, dateTypeSelector) => {
    const opts = await getUnique(fileId, columnName, dateTypeSelector);
    setValueOpts(opts);
  };

  const handleLimitsSelectedColumn = async (selected) => {
    setSelectedColumn(selected);
    const isDate = columnIsDate(selected);
    if (selected.value.length > 0 && (!isDate || dateType.value.length > 0)) {
      getValueOpts(selectedFile.value, selected.value, isDate ? dateType.value : '');
      setSelectedValues([]);
    } else {
      setValueOpts([]);
      setSelectedValues([]);
    }
  };

  const handleDateType = async (selected) => {
    setDateType(selected);
    if (selected.value.length > 0) {
      getValueOpts(selectedFile.value, selectedColumn.value, selected.value);
      setSelectedValues([]);
    } else {
      setValueOpts([]);
      setSelectedValues([]);
    }
  };

  const addTempLimit = () => {
    const uid = uuidv4();
    setTempLimits((lfilters) => ({
      ...lfilters,
      [selectedFile.value + selectedColumn.value]: {
        uid,
        database: selectedFile.value,
        filename: selectedFile.label, // remove before send
        column: selectedColumn.value,
        selector: columnIsDate(selectedColumn) ? dateType.value : '',
        type: selectedColumn.type,
        ftype: 'values',
        values: selectedValues.map((v) => v.value),
        inverse: condition.value === 'not-values',
      },
    }));
    setSelectedColumn(EMPTYSELECTION);
    setSelectedValues([]);
  };

  const removeTempLimit = (e, fKey) => {
    e.preventDefault();
    e.stopPropagation();
    setTempLimits((lfilters) => {
      const { [fKey]: _, ...auxObj } = lfilters;
      return auxObj;
    });
  };

  const editTempLimit = (e, fKey) => {
    e.preventDefault();
    e.stopPropagation();
    const old = tempLimits[fKey];
    const fileOpt = filteredFiles.find((f) => f.value === old.database);
    const columnOpt = {
      label: old.column, value: old.column, id: old.column, type: old.type,
    };
    const dateTypeOpt = (
      dateOptions.find((selector) => selector.value === old.selector) ?? EMPTYSELECTION
    );
    setSelectedFile(fileOpt);
    getColumnsOpts(fileOpt);
    setSelectedColumn(columnOpt);
    setDateType(dateTypeOpt);
    setCondition(filterTypeOptionsSimple[old.inverse ? 1 : 0]);
    setSelectedValues(old.values.map((value) => ({
      label: value, value, id: value,
    })));
    getValueOpts(fileOpt.value, columnOpt.value, dateTypeOpt.value);
  };

  const saveFilters = () => {
    submit(
      Object.entries(tempFilters).map(([key, tmpFilter]) => ({
        uid: key,
        column: tmpFilter.column,
        database: tmpFilter.database,
        type: tmpFilter.type,
        fType: 'values',
        inverse: false,
      })),
      Object.values(tempLimits).map((tmpLimit) => ({
        uid: tmpLimit.uid,
        column: tmpLimit.column,
        database: tmpLimit.database,
        type: tmpLimit.type,
        selector: tmpLimit.selector,
        ftype: 'values',
        values: tmpLimit.values,
        inverse: tmpLimit.inverse,
      })),
    );
    setGlobalFilterSelectors(tempFilters);
    setGlobalFilterLimits(tempLimits);
    showToolbar(Object.keys(tempFilters).length > 0);
    hide();
  };

  return (
    <MainContainer>
      <GridContainer>
        {isLoading && (
          <LoadingContainer>
            <Loading fill="primary" />
          </LoadingContainer>
        )}
        <LeftSide>
          <TabContainer>
            <TabItem onClick={setVarsTab} active={tab === 'VARS'}>
              Variáveis
            </TabItem>
            <TabItem onClick={setLimitsTab} active={tab === 'LIMITS'}>
              Limitadores
            </TabItem>
          </TabContainer>
          {tab === 'VARS' && (
            <ScrollContainer>
              <span>
                Selecione as variáveis que você quer utilizar como filtros globais:
              </span>
              <Select
                label="Arquivo"
                options={filteredFiles}
                value={selectedFile}
                onChange={handleFileSelection}
                sortBy="ascending"
                atModal
              />
              <Select
                label="Coluna"
                options={columnsOpts}
                value={selectedColumn}
                onChange={setSelectedColumn}
                atModal
                searchable
                sortBy="ascending"
              />
              {Object.keys(tempFilters).length >= 10 ? (
                <FullMsg>*Limite de variáveis atingido!</FullMsg>
              ) : (
                <Button
                  color="secondary"
                  onClick={addTempFilter}
                  disabled={selectedColumn.value === ''}
                >
                  Adicionar variável
                </Button>
              )}
            </ScrollContainer>
          )}
          {tab === 'LIMITS' && (
            <ScrollContainer>
              <span>
                Adicione limites para as opções dos filtros globais (simples):
              </span>
              <Select
                label="Arquivo"
                options={filteredFiles}
                value={selectedFile}
                onChange={handleFileSelection}
                atModal
                sortBy="ascending"
              />
              <Select
                label="Coluna"
                options={columnsOpts}
                value={selectedColumn}
                onChange={handleLimitsSelectedColumn}
                atModal
                searchable
                sortBy="ascending"
              />
              <InputRow>
                {columnIsDate(selectedColumn) && (
                  <Select
                    label="Formato"
                    options={dateOptions}
                    value={dateType}
                    onChange={handleDateType}
                    atModal
                    searchable
                    wrapperStyle={{ marginRight: '5px' }}
                  />
                )}
                <Select
                  label="Condição"
                  options={filterTypeOptionsSimple}
                  value={condition}
                  onChange={setCondition}
                  atModal
                  searchable
                />
              </InputRow>
              {selectedColumn.value?.length > 0 && valueOpts.length > 0 && (
                <Select
                  label="Valores"
                  options={valueOpts}
                  value={selectedValues}
                  onChange={setSelectedValues}
                  atModal
                  searchable
                  multiple
                  stayOpen
                  clearable
                  selectAll
                  sortBy="ascending"
                />
              )}
              {Object.keys(tempLimits).length >= 10 ? (
                <FullMsg>*Limite de filtros atingido!</FullMsg>
              ) : (
                <Button
                  color="secondary"
                  onClick={addTempLimit}
                  disabled={
                    selectedValues.length <= 0 || (
                      columnIsDate(selectedColumn) && !dateType?.value?.length > 0
                    )
                  }
                >
                  Adicionar filtro
                </Button>
              )}
            </ScrollContainer>
          )}
        </LeftSide>
        <RightSide>
          {tab === 'VARS' && (
            <>
              {Object.keys(tempFilters).length > 0 ? (
                Object.keys(tempFilters).map((tfKey) => (
                  <Tooltip
                    key={tfKey}
                    text={(
                      <InfoTooltip>
                        <span>{`Arquivo: ${tempFilters[tfKey].filename}`}</span>
                      </InfoTooltip>
                    )}
                    direction="top"
                    atModal
                  >
                    <ColumnBadge varType={tempFilters[tfKey].type}>
                      <div>
                        {op[tempFilters[tfKey].type]}
                        <h5>{tempFilters[tfKey].column}</h5>
                        <IconButton
                          size="small"
                          variant="contained"
                          color="secondary"
                          style={{
                            boxShadow: 'none',
                            margin: '0 2px 0 auto',
                            aspectRatio: '1',
                            width: '20px',
                            height: '20px',
                          }}
                          onClick={(e) => removeTempFilter(e, tfKey)}
                        >
                          <Close />
                        </IconButton>
                      </div>
                    </ColumnBadge>
                  </Tooltip>
                ))
              ) : (
                <EmptyMsg>
                  Nenhuma variável foi adicionada.
                </EmptyMsg>
              )}
            </>
          )}
          {tab === 'LIMITS' && (
            <>
              {Object.keys(tempLimits).length > 0 ? (
                Object.entries(tempLimits).map(([lKey, lFilter]) => (
                  <Tooltip
                    key={lKey}
                    text={(
                      <InfoTooltip>
                        <span>{`Arquivo: ${lFilter.filename}`}</span>
                        <span>{`Coluna: ${lFilter.column}`}</span>
                        <span>{`Valores ${lFilter.inverse ? 'não' : ''} selecionados`}</span>
                        <span>{lFilter.values.join(', ')}</span>
                      </InfoTooltip>
                    )}
                    direction="top"
                    atModal
                  >
                    <ColumnBadge varType={lFilter.type}>
                      <div>
                        {op[lFilter.type]}
                        <h5>{lFilter.column}</h5>
                        <IconButton
                          size="small"
                          variant="contained"
                          color="secondary"
                          style={{
                            boxShadow: 'none',
                            margin: '0 2px 0 auto',
                            aspectRatio: '1',
                            width: '20px',
                            height: '20px',
                          }}
                          onClick={(e) => editTempLimit(e, lKey)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size="small"
                          variant="contained"
                          color="secondary"
                          style={{
                            boxShadow: 'none',
                            margin: '0 2px 0 0',
                            aspectRatio: '1',
                            width: '20px',
                            height: '20px',
                          }}
                          onClick={(e) => removeTempLimit(e, lKey)}
                        >
                          <Close />
                        </IconButton>
                      </div>
                      <div className="values-list">
                        <b>{lFilter.inverse ? 'Exceto:' : 'Apenas:'}</b>
                        <span>{lFilter.values.join(', ')}</span>
                      </div>
                    </ColumnBadge>
                  </Tooltip>
                ))
              ) : (
                <EmptyMsg>
                  Nenhuma filtro foi adicionada.
                </EmptyMsg>
              )}
            </>
          )}
        </RightSide>
      </GridContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button style={{ margin: '5px' }} size="large" onClick={saveFilters}>
          Salvar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

GlobalFiltersColumns.propTypes = {
  usedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  getFileColumns: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  globalFiltersState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ])),
  globalFilterLimitsState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ])),
  showToolbar: PropTypes.func.isRequired,
  getUnique: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

GlobalFiltersColumns.defaultProps = {
  usedFiles: [],
  globalFiltersState: [{}, () => {}],
  globalFilterLimitsState: [{}, () => {}],
};

export default GlobalFiltersColumns;
