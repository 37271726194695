/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBoxPlot } from '@nivo/boxplot';

import CustomLegend from '../../utils/chartTools/createLegend';
import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import customAxis from '../../utils/formatCharts/formatAxis';
import { dataSort, genDataBoxPlot } from '../../utils/formatCharts/handleData';
import { getBiggestTick, getSmallestTick } from '../../utils/formatCharts/getAxisTicks';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const BoxPlot = ({
  data, styleConfig, isEdit, globalTheme, handleDrilldownClick,
}) => {
  const [chartData, setChartData] = useState({ ready: false });
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [hiddenIds, setHiddenIds] = useState([]);

  useEffect(() => {
    setChartData(genDataBoxPlot(data, styleConfig));
  }, [data]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  const dataFrom = useMemo(() => {
    if (styleConfig?.ColorByControl === 'keys') return 'subGroup';
    if (styleConfig?.ColorByControl === 'indexes') return 'group';
    if (chartData?.subIndexes?.length > 1) return 'subGroup';
    return 'group';
  }, [styleConfig?.ColorByControl, chartData?.subIndexes?.length]);

  useEffect(() => {
    if (chartData.ready) {
      setFilteredChartData(chartData.data.filter((item) => !hiddenIds.includes(item[dataFrom])));
    }
  }, [chartData, hiddenIds]);

  const biggestTick = useMemo(() => (
    getBiggestTick(chartData.biggest || 0, styleConfig)
  ), [chartData.biggest]);
  const smallestTick = useMemo(() => (
    getSmallestTick(chartData.smallest || 0, styleConfig)
  ), [chartData.smallest]);

  const createTooltip = (val) => customTooltip(val, 'boxplot', styleConfig, handleDrilldownClick);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'needsNumericValue',
          !chartData?.isBoxplot,
        ],
      ])}
    >
      <ResponsiveBoxPlot
        key={`boxplot-${styleConfig.LayoutControl}`}
        data={filteredChartData}
        onClick={handleDrilldownClick ? ((dataPoint) => {
          handleDrilldownClick(dataPoint?.data?.uid, dataPoint?.subGroup);
        }) : null}
        subGroupBy={chartData?.subIndexes?.length > 1 ? 'subGroup' : ''}
        /* GENERAL */
        layout={styleConfig.LayoutControl}
        valueScale={{ type: styleConfig.ValueScaleControl }}
        padding={styleConfig?.PaddingControl?.value}
        innerPadding={styleConfig?.InnerPaddingControl?.value}
        margin={styleConfig?.Margin}
        minValue={styleConfig?.MinValueControl?.checked ? smallestTick : (
          styleConfig?.MinValueControl?.value
        )}
        maxValue={styleConfig.MaxValueControl?.checked ? biggestTick : (
          styleConfig.MaxValueControl?.value
        )}
        /* STYLE */
        borderRadius={styleConfig?.BorderRadiusControl?.value}
        borderWidth={styleConfig?.BorderWidthControl?.value}
        borderColor={styleConfig.BorderColorControl}
        medianWidth={styleConfig.MedianWidthControl}
        medianColor={styleConfig?.MedianColorControl}
        whiskerWidth={styleConfig?.WhiskerWidthControl}
        whiskerEndSize={styleConfig?.WhiskerEndSizeControl}
        whiskerColor={styleConfig?.WhiskerColorControl}
        colors={colorSelector({
          dataLength: dataFrom === 'group' ? chartData.indexes.length : chartData.subIndexes.length,
          config: styleConfig,
          colorKey: dataFrom,
          globalTheme,
        })}
        /* AXIS */
        enableGridX={styleConfig?.EnableGridX}
        enableGridY={styleConfig?.EnableGridY}
        axisTop={customAxis('top', styleConfig, styleConfig.LayoutControl === 'horizontal')}
        axisLeft={customAxis('left', styleConfig, styleConfig.LayoutControl !== 'horizontal')}
        axisBottom={customAxis('bottom', styleConfig, styleConfig.LayoutControl === 'horizontal')}
        axisRight={customAxis('right', styleConfig, styleConfig.LayoutControl !== 'horizontal')}
        /* INTERACTIVE */
        isInteractive={styleConfig.InteractiveControl}
        tooltip={createTooltip}
        animate={styleConfig.InteractiveControl ? Boolean(styleConfig.Animate) : false}
        motionConfig={styleConfig.InteractiveControl ? styleConfig.MotionConfig : 'default'}
        /* LEGENDS */
        colorBy={dataFrom}
        layers={[
          'grid', 'axes', 'boxPlots', 'markers',
          styleConfig?.LegendControl?.checked ? (dt) => {
            const dtParts = [...chartData.data?.reduce((aux, d) => {
              aux.add(dataFrom === 'group' ? d.group : d.subGroup);
              return aux;
            }, new Set())];
            return (
              <CustomLegend
                chartDataAr={dtParts}
                colorParts={dt.boxPlots.reduce((aux, p) => {
                  aux[dataFrom === 'group' ? p.group : p.subGroup] = p.color;
                  return aux;
                }, {})}
                sizes={{
                  width: dt.innerWidth,
                  height: dt.innerHeight,
                }}
                config={styleConfig}
                setToggleIds={setHiddenIds}
                isEdit={isEdit}
              />
            );
          } : undefined,
          'annotations',
        ]}
        /* THEME */
        theme={{
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
          axis: {
            legend: {
              text: {
                fontSize: styleConfig?.AxisGlobal?.legendFontSize,
                fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
                fill: styleConfig?.AxisGlobal?.color,
              },
            },
          },
        }}
        /* MARKERS */
        markers={styleConfig.ShowMarkerControl ? styleConfig.MarkersList.reduce((aux, lines) => {
          if (lines.showLine) {
            aux.push({
              ...lines,
              textStyle: {
                ...lines.textStyle,
                fontFamily: `'${lines.textStyle.fontFamily}', Arial`,
              },
            });
          }
          return aux;
        }, []) : []}
        //
        activeOpacity={1}
        inactiveOpacity={1}
      />
    </KpiStatus>
  ) : <></>;
};

BoxPlot.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
  handleDrilldownClick: PropTypes.func,
};

BoxPlot.defaultProps = {
  globalTheme: {},
  handleDrilldownClick: null,
};

export default BoxPlot;
