import { useEffect } from 'react';

/** Hook to load the web chat support for TR. */
const useWebChatTR = () => {
  useEffect(() => {
    let script;
    if (process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics') {
      // eslint-disable-next-line no-underscore-dangle
      window._owc = window._owc || [];
      // eslint-disable-next-line no-undef
      _owc.push(['_setOwcChannelHash', 'fc6fa94d6ce3a1075be6c6a6c047fe09']);
      script = document.createElement('script');
      script.src = 'https://thomson.rcxit.com.br:10443/webchat/sdk/owc.js';
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      if (process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'legalone-analytics') {
        document.body.removeChild(script);
      }
    };
  }, []);
};

export default useWebChatTR;
