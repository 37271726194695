/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

import CustomLegend from '../../utils/chartTools/createLegend';
import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import customAxis from '../../utils/formatCharts/formatAxis';
import { dataSort, genData2DWithKeys } from '../../utils/formatCharts/handleData';
import { getBiggestTick } from '../../utils/formatCharts/getAxisTicks';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Bar = ({
  data, styleConfig, isEdit, globalTheme, handleDrilldownClick,
}) => {
  const [chartData, setChartData] = useState({ ready: false });
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [hiddenIds, setHiddenIds] = useState([]);
  useEffect(() => {
    setChartData(genData2DWithKeys(data, styleConfig));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setFilteredChartData(chartData.data.filter((item) => !hiddenIds.includes(item.uid)));
    }
  }, [chartData, hiddenIds]);

  const biggestTick = useMemo(() => {
    if (styleConfig.GroupModeControl === 'stacked') {
      return getBiggestTick(chartData?.biggests?.global || 0, styleConfig);
    } return getBiggestTick(chartData?.biggests?.categories || 0, styleConfig);
  }, [chartData, styleConfig.GroupModeControl]);

  const dataFrom = () => {
    if (styleConfig?.ColorByControl === 'keys') return 'id';
    if (styleConfig?.ColorByControl === 'indexes') return 'indexValue';
    if (chartData.keys.length > 1) return 'id';
    return 'indexValue';
  };

  const createLabel = (label) => {
    const val = customValueLabel(label.value, styleConfig);
    const { GroupModeControl, LayoutControl, LabelFormatControl } = styleConfig;
    if (GroupModeControl === 'stacked' || LayoutControl !== 'vertical' || LabelFormatControl === 'Central') return val;
    return <tspan y={-10}>{val}</tspan>;
  };

  const createTooltip = (val) => customTooltip(val, 'bar', styleConfig, handleDrilldownClick);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
      ])}
    >
      <ResponsiveBar
        data={dataFrom() === 'indexValue' ? filteredChartData : chartData.data}
        keys={chartData.keys}
        onClick={handleDrilldownClick ? ((dataPoint) => {
          handleDrilldownClick(dataPoint?.data?.uid, dataPoint?.id);
        }) : null}
        indexBy="label"
        valueScale={{ type: styleConfig.ValueScaleControl || 'linear' }}
        /* GENERAL */
        margin={styleConfig?.Margin || {
          top: 50,
          right: 145,
          bottom: 55,
          left: 60,
        }}
        groupMode={styleConfig.GroupModeControl || 'stacked'}
        layout={styleConfig.LayoutControl || 'vertical'}
        reverse={!!styleConfig?.ReverseControl?.checked || false}
        minValue={styleConfig?.MinValueControl?.checked ? 'auto' : styleConfig?.MinValueControl?.value}
        maxValue={styleConfig.MaxValueControl?.checked
          ? biggestTick : styleConfig.MaxValueControl?.value}
        padding={styleConfig?.PaddingControl?.value || 0.1}
        innerPadding={styleConfig?.InnerPaddingControl?.value || 0}
        /* BORDER */
        borderRadius={styleConfig?.BorderRadiusControl?.value || 5}
        borderWidth={styleConfig?.BorderWidthControl?.value || 0}
        borderColor={
          styleConfig.BorderColorControl
            ? styleConfig.BorderColorControl
            : { from: 'color', modifiers: [['darker', 1.6]] }
        }
        /* LABEL */
        enableLabel={styleConfig.EnableLabelControl ? styleConfig.EnableLabelControl.checked : true}
        labelSkipWidth={styleConfig.labelSkipWidth || 0}
        labelSkipHeight={styleConfig.labelSkipHeight || 0}
        labelTextColor={styleConfig.LabelTextColorControl}
        label={createLabel}
        axisTop={customAxis('top', styleConfig, styleConfig.LayoutControl === 'horizontal')}
        axisLeft={customAxis('left', styleConfig, styleConfig.LayoutControl !== 'horizontal')}
        axisBottom={customAxis('bottom', styleConfig, styleConfig.LayoutControl === 'horizontal')}
        axisRight={customAxis('right', styleConfig, styleConfig.LayoutControl !== 'horizontal')}
        /* TOOLTIP */
        isInteractive={styleConfig.InteractiveControl}
        tooltip={createTooltip}
        /* LEGEND */
        enableGridX={styleConfig?.EnableGridX ?? false}
        enableGridY={styleConfig?.EnableGridY ?? false}
        legends={styleConfig?.LegendControl?.checked && dataFrom() === 'id' ? [{
          ...styleConfig?.LegendPlacementControl?.value,
          dataFrom: dataFrom() === 'id' ? 'keys' : 'indexes',
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
          symbolSize: 18,
          symbolShape: styleConfig?.LegendSymbolControl || 'circle',
          text: { textDecoration: 'line-through' },
          effects: [{ on: 'hover', style: { itemTextColor: '#000' } }],
          toggleSerie: !isEdit,
        }] : []}
        layers={['grid', 'axes', 'bars', 'markers', dataFrom() === 'indexValue' ? (dt) => (
          styleConfig?.LegendControl?.checked ? (
            <CustomLegend
              chartDataAr={chartData.data}
              colorParts={dt.bars.reduce((aux, p) => {
                aux[p.data.indexValue] = p.color;
                return aux;
              }, {})}
              sizes={{
                width: dt.innerWidth,
                height: dt.innerHeight,
              }}
              config={styleConfig}
              setToggleIds={setHiddenIds}
              isEdit={isEdit}
            />
          ) : <></>
        ) : 'legends', 'annotations']}
        /* STYLES/THEMES */
        colorBy={dataFrom()}
        colors={dataFrom() === 'id' ? colorSelector({
          dataLength: chartData.keys.length, config: styleConfig, colorKey: 'id', globalTheme,
        }) : colorSelector({
          dataLength: chartData.data.length, config: styleConfig, colorKey: 'indexValue', globalTheme,
        })}
        theme={{
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
          labels: {
            text: {
              fontSize: `${styleConfig?.LabelsFontSizeControl ? styleConfig?.LabelsFontSizeControl.value : 13}px`,
              fontFamily: `'${styleConfig?.LabelsFontFamilyControl?.value}', Arial`,
              transform: `translate(${styleConfig?.LabelTranslateX ? styleConfig?.LabelTranslateX : 0}px, ${styleConfig?.LabelTranslateY ? styleConfig?.LabelTranslateY : 0}px) rotate(${styleConfig?.LabelRotationControl ? styleConfig?.LabelRotationControl : 0}deg)`,
              transformOrigin: 'center center',
              transformBox: 'fill-box',
            },
          },
          axis: {
            legend: {
              text: {
                fontSize: styleConfig?.AxisGlobal?.legendFontSize,
                fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
                fill: styleConfig?.AxisGlobal?.color,
              },
            },
            ticks: {
              text: {
                fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
                fontSize: styleConfig?.AxisGlobal?.fontSize,
                fill: styleConfig?.AxisGlobal?.color,
              },
            },
          },
        }}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
        markers={styleConfig.ShowMarkerControl
          ? styleConfig.MarkersList?.reduce((aux, lines) => {
            if (lines.showLine) {
              aux.push({
                ...lines,
                textStyle: {
                  ...lines.textStyle,
                  fontFamily: `'${lines.textStyle.fontFamily}', Arial`,
                },
              });
            }
            return aux;
          }, []) : []}
      />
    </KpiStatus>
  ) : <></>;
};

Bar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
  handleDrilldownClick: PropTypes.func,
};

Bar.defaultProps = {
  globalTheme: {},
  handleDrilldownClick: null,
};

export default Bar;
