import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MiniLoading from '../../../core/MiniLoading';

import {
  StyledEmptyKpiCard, StyledLoadContainer, ButtonsContainer, ButtonsColumn,
} from './styled/ErrorKpi.styled';
import Button from '../../../core/Button';

const messagesMap = {
  emptyDataSet: `O seu KPI foi criado e as informações nos dados estão vazias, portanto não podemos renderizar o seu gráfico.
  Verifique as colunas e filtros fornecidos na criação de seu gráfico!`,
  tooMuchCategories: `O seu KPI possui mais de 100 categorias, o que está impactando no consumo de memória do seu navegador, portanto não podemos renderizar o seu gráfico.
  Recomendamos utilizar agrupamentos e filtros para otimização da leitura! (Recomendado 20 categorias)`,
  hasNegativeValues: `O seu KPI possui valores negativos que não são aceitos por esse formato de gráfico, portanto não podemos renderizá-lo.
  Recomendamos a utilização de outros formatos de gráficos para esses dados!`,
  noLineSelected: `Este formato de gráfico precisa ter uma variável selecionada como Linha para ser renderizado.
  Recomendamos a utilização de outros formatos de gráficos para esses dados!`,
  needsDateFormat: `Este formato de gráfico precisa de uma variável de DATA selecionada como Linha, no formato A-M-D.
  Gráfico de calendário não será renderizado!`,
  needsNumericValue: `Este formato de gráfico precisa de uma variável numérica selecionada como Valor.
  Diagrama de caixa não será renderizado!`,
  needsTwoNumericValue: `Este formato de gráfico precisa de duas variável numérica selecionadas como Valores.
  Diagrama de dispersão não será renderizado!`,
  invalidInStory: `Este indicador só consegue acessar os dados mais recentes.
  Portanto, não será renderizada em um story.`,
};

const reasonsMap = {
  emptyDataSet: 'Indicador Vazio',
  tooMuchCategories: 'KPI com muitas categorias',
  hasNegativeValues: 'KPI com valores negativos',
  noLineSelected: 'KPI sem linha',
  needsDateFormat: 'KPI com valores inválidos',
  needsNumericValue: 'KPI com valores inválidos',
  needsTwoNumericValue: 'KPI com valores inválidos',
  invalidInStory: 'Indicador inconsistente',
};

/** Define a limit for the number of categories in the KPI to prevent excessive memory usage */
export const KPILIMIT = 101;

const KpiStatus = ({
  isLoading, autoValueModifiers, conditions, reason, message, children,
}) => {
  const error = useMemo(() => (
    [...conditions].find(([, condition]) => condition)?.[0]
  ), [conditions]);

  const lesserGroup = () => autoValueModifiers('group', 'lesser_inc');
  const greaterGroup = () => autoValueModifiers('group', 'greater_inc');
  const leastFilter = () => autoValueModifiers('filter', 'least');
  const mostFilter = () => autoValueModifiers('filter', 'most');

  return (
    <>
      {isLoading && (
        <StyledLoadContainer><MiniLoading fill="primary" /></StyledLoadContainer>
      )}
      {error && !isLoading && (
        <StyledEmptyKpiCard>
          <h2>{reason ?? reasonsMap[error]}</h2>
          <p>{message ?? messagesMap[error]}</p>
          {error === 'tooMuchCategories' && Boolean(autoValueModifiers) && (
            <ButtonsContainer>
              <ButtonsColumn>
                <Button
                  color="secondary"
                  size="small"
                  textTransform="none"
                  onClick={lesserGroup}
                  style={{ margin: '2px' }}
                >
                  Agrupar os menores valores
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  textTransform="none"
                  onClick={greaterGroup}
                  style={{ margin: '2px' }}
                >
                  Agrupar os maiores valores
                </Button>
              </ButtonsColumn>
              <ButtonsColumn>
                <Button
                  color="secondary"
                  size="small"
                  textTransform="none"
                  onClick={leastFilter}
                  style={{ margin: '2px' }}
                >
                  Mostrar os 20 menores valores
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  textTransform="none"
                  onClick={mostFilter}
                  style={{ margin: '2px' }}
                >
                  Mostrar os 20 maiores valores
                </Button>
              </ButtonsColumn>
            </ButtonsContainer>
          )}
        </StyledEmptyKpiCard>
      )}
      {!error && !isLoading && children}
    </>
  );
};

KpiStatus.propTypes = {
  isLoading: PropTypes.bool,
  conditions: PropTypes.instanceOf(Map),
  reason: PropTypes.string,
  message: PropTypes.string,
  autoValueModifiers: PropTypes.func,
  children: PropTypes.node.isRequired,
};

KpiStatus.defaultProps = {
  isLoading: null,
  conditions: new Map(),
  reason: null,
  message: null,
  autoValueModifiers: null,
};

export default KpiStatus;
