/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';
import { dataSort, genData1D } from '../../utils/formatCharts/handleData';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Pie = ({
  data, styleConfig, isEdit, globalTheme, handleDrilldownClick,
}) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genData1D(data, styleConfig));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  const createLabel = (label) => customValueLabel(label.value, styleConfig);

  const createTooltip = (val) => customTooltip(val, 'pie', styleConfig, handleDrilldownClick);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
        [
          'hasNegativeValues',
          chartData.hasNegative,
        ],
      ])}
    >
      <ResponsivePie
        data={chartData.data}
        margin={
          styleConfig?.Margin || {
            top: 50,
            right: 130,
            bottom: 50,
            left: 60,
          }
        }
        onClick={handleDrilldownClick ? ((dataPoint) => {
          handleDrilldownClick(dataPoint?.data?.id);
        }) : null}
        startAngle={-45}
        padAngle={styleConfig?.PaddingAngleControl?.value}
        cornerRadius={styleConfig?.CornerRadiusControl?.value}
        innerRadius={styleConfig?.InnerRadiusControl?.value}
        borderWidth={styleConfig?.BorderWidthControl?.value}
        // TOOLTIPS
        isInteractive={styleConfig?.InteractiveControl}
        tooltip={createTooltip}
        enableArcLinkLabels={styleConfig?.RadialLabelControl?.checked}
        enableArcLabels={styleConfig?.EnableLabelControl?.checked}
        colors={colorSelector({
          dataLength: chartData.data?.length,
          config: styleConfig,
          colorKey: 'id',
          globalTheme,
        })}
        borderColor={
          styleConfig.BorderColorControl
            ? styleConfig.BorderColorControl
            : { from: 'color', modifiers: [['darker', 1.6]] }
        }
        // LABELs
        arcLabel={createLabel}
        arcLabelsTextColor={styleConfig?.SliceTextColorControl}
        arcLinkLabelsTextColor={styleConfig?.LabelTextColorControl}
        arcLinkLabelsOffset={6}
        arcLinkLabelsTextOffset={8}
        arcLinkLabelsDiagonalLength={24}
        arcLinkLabelsStraightLength={24}
        arcLinkLabelsThickness={1}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={0}
        legends={styleConfig?.LegendControl?.checked ? [{
          ...styleConfig?.LegendPlacementControl?.value,
          itemWidth: 100,
          itemHeight: 18,
          symbolSize: 18,
          itemTextColor: '#999',
          symbolShape: styleConfig?.LegendSymbolControl || 'circle',
          itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
          justify: false,
          effects: [{
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          }],
          toggleSerie: !isEdit,
        }] : []}
        theme={{
          labels: {
            text: {
              fontSize: parseInt(styleConfig?.LabelsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LabelsFontFamilyControl?.value}', Arial`,
              transformOrigin: 'center center',
              transformBox: 'fill-box',
            },
          },
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
        }}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
        activeOuterRadiusOffset={styleConfig.InteractiveControl && !!styleConfig.Animate
          ? (+styleConfig.PartExtension || 0) : 0}
      />
    </KpiStatus>
  ) : <></>;
};

Pie.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
  handleDrilldownClick: PropTypes.func,
};

Pie.defaultProps = {
  globalTheme: {},
  handleDrilldownClick: null,
};

export default Pie;
