/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveRadialBar } from '@nivo/radial-bar';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import CustomLegend from '../../utils/chartTools/createLegend';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';
import { dataSortPoints, genData2DWithPoints } from '../../utils/formatCharts/handleData';

const RadialBar = ({
  data, styleConfig, isEdit, globalTheme, handleDrilldownClick,
}) => {
  const [chartData, setChartData] = useState({ ready: false });
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [hiddenIds, setHiddenIds] = useState([]);

  useEffect(() => {
    setChartData(genData2DWithPoints(data, styleConfig, true));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSortPoints(chartData.data, styleConfig, true),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setFilteredChartData(chartData.data.map((dt) => ({
        ...dt,
        data: dt.data.filter((item) => !hiddenIds.includes(item.id)),
      })));
    }
  }, [chartData, hiddenIds]);

  const createLabel = (label) => customValueLabel(label.value, styleConfig);

  const createTooltip = (val) => customTooltip(val, 'radialbar', styleConfig, handleDrilldownClick);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
        [
          'hasNegativeValues',
          chartData.hasNegative,
        ],
      ])}
    >
      <ResponsiveRadialBar
        data={filteredChartData}
        /* GENERAL */
        margin={
          styleConfig?.Margin || {
            top: 50,
            right: 130,
            bottom: 50,
            left: 60,
          }
        }
        onClick={handleDrilldownClick ? ((dataPoint) => {
          if (typeof data?.columns?.[0] === 'string') {
            handleDrilldownClick(dataPoint?.category, null);
          } else {
            handleDrilldownClick(dataPoint?.groupId, dataPoint?.category);
          }
        }) : null}
        padAngle={styleConfig?.PaddingAngleControl?.value}
        innerRadius={styleConfig?.InnerRadiusControl?.value}
        padding={styleConfig?.PaddingControl?.value || 0}
        maxValue={styleConfig.MaxValueControl?.checked ? 'auto' : styleConfig.MaxValueControl?.value}
        startAngle={styleConfig?.StartAngleControl || 0}
        endAngle={styleConfig?.EndAngleControl || 0}
        /* BORDER */
        borderWidth={styleConfig?.BorderWidthControl?.value}
        borderColor={
          styleConfig.BorderColorControl
            ? styleConfig.BorderColorControl
            : { from: 'color', modifiers: [['darker', 1.6]] }
        }
        /* STYLES/THEMES */
        colors={colorSelector({
          dataLength: chartData.data[0].data.length,
          config: styleConfig,
          colorKey: 'category',
          globalTheme,
        })}
        theme={{
          labels: {
            text: {
              fontSize: parseInt(styleConfig?.LabelsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LabelsFontFamilyControl?.value}', Arial`,
              transform: `translate(${styleConfig?.LabelTranslateX ? styleConfig?.LabelTranslateX : 0}px, ${styleConfig?.LabelTranslateY ? styleConfig?.LabelTranslateY : 0}px) rotate(${styleConfig?.LabelRotationControl ? styleConfig?.LabelRotationControl : 0}deg)`,
              transformOrigin: 'center center',
              transformBox: 'fill-box',
            },
          },
          legends: {
            text: {
              fontSize: parseInt(styleConfig?.LegendsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: parseInt(styleConfig?.AxisGlobalRadialFontSize, 10),
                fontFamily: `'${styleConfig?.AxisGlobalRadialFontFamily}', Arial`,
                fill: styleConfig?.AxisGlobalRadialFontColor,
              },
            },
          },
        }}
        /* TRACK */
        enableTracks={!!styleConfig?.EnableTrackControl}
        tracksColor={
          `${styleConfig?.TrackColorControl}${(styleConfig?.TrackOpacityControl * 255).toString(16).padStart(2, '0').slice(0, 2) || '00'}` || '#00000026'
        }
        /* LABEL */
        enableLabels={styleConfig?.EnableLabelControl?.checked}
        labelsTextColor={styleConfig?.LabelTextColorControl}
        label={createLabel}
        /* AXIS */
        enableRadialGrid={!!styleConfig?.EnableGridR}
        enableCircularGrid={!!styleConfig?.EnableGridC}
        radialAxisStart={styleConfig?.EnableAxisStart ? {
          tickSize: +styleConfig?.AxisRadialTickSize,
          tickPadding: +styleConfig?.AxisRadialTickPadding,
          tickRotation: +styleConfig?.AxisRadialTickRotation,
        } : null}
        radialAxisEnd={styleConfig?.EnableAxisEnd ? {
          tickSize: +styleConfig?.AxisRadialTickSize,
          tickPadding: +styleConfig?.AxisRadialTickPadding,
          tickRotation: +styleConfig?.AxisRadialTickRotation,
        } : null}
        circularAxisInner={styleConfig?.EnableAxisInner ? {
          tickSize: +styleConfig?.AxisCircularTickSize,
          tickPadding: +styleConfig?.AxisCircularTickPadding,
          tickRotation: +styleConfig?.AxisCircularTickRotation,
        } : null}
        circularAxisOuter={styleConfig?.EnableAxisOuter ? {
          tickSize: +styleConfig?.AxisCircularTickSize,
          tickPadding: +styleConfig?.AxisCircularTickPadding,
          tickRotation: +styleConfig?.AxisCircularTickRotation,
        } : null}
        /* TOOLTIP */
        isInteractive={styleConfig.InteractiveControl}
        tooltip={createTooltip}
        /* LEGEND */
        layers={['grid', 'tracks', 'bars', 'labels', (dt) => (
          styleConfig?.LegendControl?.checked ? (
            <CustomLegend
              chartDataAr={chartData.data[0].data}
              colorParts={dt.bars.reduce((aux, p) => {
                aux[p.category] = p.color;
                return aux;
              }, {})}
              sizes={{
                width: 2 * dt.center[0],
                height: 2 * dt.center[1],
              }}
              config={styleConfig}
              setToggleIds={setHiddenIds}
              isEdit={isEdit}
            />
          ) : <></>
        )]}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
        labelsSkipAngle={0}
      />
    </KpiStatus>
  ) : <></>;
};

RadialBar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
  handleDrilldownClick: PropTypes.func,
};

RadialBar.defaultProps = {
  globalTheme: {},
  handleDrilldownClick: null,
};

export default RadialBar;
