import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import Button from '../../juristec-ui/core/Button';
import Chart from '../../juristec-ui/kpis/charts';
import InsightCard from '../../juristec-ui/kpis/insight';
import IframeCard from '../../juristec-ui/kpis/iframe';
import IconButton from '../../juristec-ui/core/IconButton';
import Popover from '../../juristec-ui/core/Popover';
import Tooltip from '../../juristec-ui/core/Tooltip';
import TablePagination from '../../juristec-ui/core/TablePagination';

import KpiStatus, { KPILIMIT } from '../../juristec-ui/kpis/utils/chartTools/KpiStatus';
// import checkDataSize from '../../juristec-ui/kpis/utils/chartTools/kpiMsg';
import filterByValues from '../../juristec-ui/kpis/utils/formatCharts/filterByValues';
import groupByValues from '../../juristec-ui/kpis/utils/formatCharts/groupByValues';

import getCardStyle from '../../utils/functions/getCardStyle';

import {
  Add, AdjustScreen, ArrowRight, Close, CommentExclamation, FillScreen, Finder, Minus,
} from '../../juristec-ui/icons';

import {
  MainContainer,
  KpiWrapper,
  KpiContainer,
  ActionButtons,
  KpiTitle,
  KpiCard,
  BtnText,
  CommentArea,
  PaginationWrapper,
} from './styled/KpiViewer.styled';

const getValueBackground = (isValue, globalTheme, background) => {
  if (isValue && globalTheme?.useGlobalTheme) return { backgroundColor: globalTheme.valueBg };
  return { backgroundColor: isValue ? background : 'transparent' };
};

const titleStyle = (tStyle, globalTheme) => ({
  fontSize: `${tStyle?.fontSize ?? 20}px`,
  color: globalTheme?.useGlobalTheme ? globalTheme.titles : (tStyle?.color ?? '#000000'),
  fontFamily: `'${tStyle?.fontFamily ?? 'arial'}', Arial`,
  fontWeight: tStyle?.bold ? 'bold' : 'normal',
  textDecoration: tStyle?.underline ? 'underline' : 'none',
  fontStyle: tStyle?.italic ? 'italic' : 'normal',
});

const LIMITEDKPIS = ['Bar', 'Pie', 'Line', 'Radar', 'Funnel', 'RadialBar'];
const NOHISTORYKPIS = ['SubsetTable', 'plotly'];
const isLimitedKpi = (kpiType) => LIMITEDKPIS.includes(kpiType);
const isKpiInvalidInStory = (kpiType) => NOHISTORYKPIS.includes(kpiType);

/**
 * Exibe individualmente cada card do kpi
 */
const KpiViewer = ({
  close,
  layout,
  getKpiSize,
  cardStyles,
  kpiItem,
  pageState,
  collection,
  changeKpi,
  isDataLoading,
  drilldownKeys,
  handleDrildown,
  removeDrilldown,
}) => {
  const containerRef = useRef();
  const isDragging = useRef(false);
  const cardPos = useRef({
    top: 0, left: 0, x: 0, y: 0,
  });

  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight, width: window.innerWidth,
  });
  const [showComment, setShowComment] = useState(false);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [fillScreen, setFillScreen] = useState(false);
  const [kpiInfo, setKpiInfo] = useState();
  const [globalTheme, setGlobalTheme] = useState({ useGlobalTheme: false });
  // const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = pageState;

  useEffect(() => {
    setKpiInfo({ kpi: kpiItem, size: getKpiSize(kpiItem.id) });
  }, [kpiItem]);

  useEffect(() => {
    setGlobalTheme(
      cardStyles && kpiInfo?.kpi?.style?.UseGlobalColor !== false
        ? cardStyles : { useGlobalTheme: false },
    );
  }, [cardStyles, kpiInfo]);

  // Adiciona um observador ao tamanho da janela
  useEffect(() => {
    function handleResize() {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Funcao que lida com os tamanhos dos cards do kpi,
   * recalculando para que seja exibido da melhor forma na tela
   * @returns {{ rWidth: Number, rHeight: Number }} um objeto com as novas medidas do card
   */
  const resizeKpi = () => {
    // Tamanho para kpi tabela
    if (kpiInfo?.kpi?.type === 'Table') {
      return {
        rWidth: windowDimensions.width - 120,
        rHeight: Math.min(kpiInfo?.size?.h, windowDimensions.height - 120),
      };
    }
    // Tamanho para kpi de valor ou insigth
    if (['label', 'smartLabel', 'Value'].includes(kpiInfo?.kpi?.type)) {
      return {
        rWidth: Math.min(kpiInfo?.size?.w, windowDimensions.width - 120),
        rHeight: Math.min(kpiInfo?.size?.h, windowDimensions.height - 120),
      };
    }

    // Preencher tela
    if (fillScreen) {
      return {
        rWidth: (windowDimensions.width - 120) * zoomFactor,
        rHeight: (windowDimensions.height - 120) * zoomFactor,
      };
    }

    // Ajuste proporcional
    const hDiff = windowDimensions.height - kpiInfo?.size?.h;
    const wDiff = windowDimensions.width - kpiInfo?.size?.w;
    if (hDiff < wDiff) {
      const rWidth = (kpiInfo?.size?.w + hDiff) - 120;
      const rHeight = windowDimensions.height - 120;
      return rWidth > 0 && rHeight > 0 ? {
        rWidth: rWidth * zoomFactor,
        rHeight: rHeight * zoomFactor,
      } : {
        rWidth: kpiInfo?.size?.w * zoomFactor,
        rHeight: kpiInfo?.size?.h * zoomFactor,
      };
    }
    const rWidth = windowDimensions.width - 120;
    const rHeight = (kpiInfo?.size?.h + wDiff) - 120;
    return rWidth > 0 && rHeight > 0 ? {
      rWidth: rWidth * zoomFactor,
      rHeight: rHeight * zoomFactor,
    } : {
      rWidth: kpiInfo?.size?.w * zoomFactor,
      rHeight: kpiInfo?.size?.h * zoomFactor,
    };
  };

  /**
   * Lida com o pressionamento do mouse no card, iniciando o arrasto
   * @param {*} e Evento
   */
  const mouseDownHandle = (e) => {
    if (e.button !== 0 || ['Table', 'label', 'smartLabel', 'Value'].includes(kpiInfo?.kpi?.type)) return;
    e.preventDefault();
    cardPos.current = {
      top: containerRef.current.scrollTop,
      left: containerRef.current.scrollLeft,
      x: e.clientX,
      y: e.clientY,
    };
    isDragging.current = true;
    containerRef.current.style.cursor = 'grabbing';
  };

  /**
   * Funcao que lida com o arrasto do card,
   * apenas quando o botao esquerdo do mouse estiver pressionado
   * @param {*} e Evento
   */
  const mouseMoveHandle = (e) => {
    if (isDragging.current) {
      containerRef.current.scrollTop = cardPos.current.top - (e.clientY - cardPos.current.y) * 2;
      containerRef.current.scrollLeft = cardPos.current.left - (e.clientX - cardPos.current.x) * 2;
    }
  };

  /**
   * Funcao para interromper o arrasto do card
   */
  const stopDragging = () => {
    if (isDragging.current) {
      isDragging.current = false;
      containerRef.current.style.cursor = 'grab';
    }
  };

  /**
   * Retorna o zoom para o valor inicial de 100%
   */
  const resetZoom = () => {
    setZoomFactor(1);
  };

  /**
   * Lida com o fechamendo do componente, retornando algumas preferencias para os valores padroes
   */
  const handleClose = () => {
    setFillScreen(false);
    resetZoom();
    changeKpi(null);
    close();
  };

  /**
   * Aumenta o zoom, 10% a mais em cada chamada, maximo de 200%
   */
  const increaseZoom = () => {
    const newZoomFactor = Number((zoomFactor + 0.1).toFixed(2));
    setZoomFactor(newZoomFactor > 2 ? 2 : newZoomFactor);
  };

  /**
   * Reduz o zoom, 10% a menos em cada chamada, minimo de 10%
   */
  const decreaseZoom = () => {
    const newZoomFactor = Number((zoomFactor - 0.1).toFixed(2));
    // minimo 10% do tamanho original
    setZoomFactor(newZoomFactor < 0.1 ? 0.1 : newZoomFactor);
  };

  /**
   * Troca para o proximo kpi, seguindo a ordem do layout e em relacao ao kpi atual
   */
  const nextKpi = () => {
    const kpiLayoutIndex = layout?.findIndex((l) => l.i === kpiInfo?.kpi?.id);
    const kId = layout[kpiLayoutIndex < layout.length - 1 ? kpiLayoutIndex + 1 : 0]?.i;
    changeKpi(kId);
    setFillScreen(false);
    resetZoom();
  };

  /**
   * Troca para o kpi anterior, seguindo a ordem do layout e em relacao ao kpi atual
   */
  const prevKpi = () => {
    const kpiLayoutIndex = layout?.findIndex((l) => l.i === kpiInfo?.kpi?.id);
    const kId = layout[kpiLayoutIndex > 0 ? kpiLayoutIndex - 1 : layout.length - 1]?.i;
    changeKpi(kId);
    setFillScreen(false);
    resetZoom();
  };

  /**
   * Fecha o popover de comentarios
   */
  const closeComment = () => {
    setShowComment(false);
  };

  /**
   * Abre o popover de comentarios
   */
  const openComment = () => {
    setShowComment(true);
  };

  /**
   * Troca o estilo de exibicao do card
   */
  const toggleAdjust = () => {
    setFillScreen((o) => !o);
  };

  // Adiciona a troca de kpi para as setas do teclado
  useEffect(() => {
    const handleKey = (e) => {
      if (e.key === 'ArrowRight') {
        nextKpi();
      } else if (e.key === 'ArrowLeft') {
        prevKpi();
      }
    };

    window.addEventListener('keydown', handleKey);
    return () => window.removeEventListener('keydown', handleKey);
  }, [kpiInfo?.kpi]);

  function modifyData(filterList, groupList, data, lineName) {
    let newData = data;
    if (filterList?.length > 0) {
      newData = filterByValues(filterList, newData, lineName);
    }
    if (groupList?.length > 0) {
      newData = groupByValues(groupList, newData, lineName);
    }
    return newData;
  }
  const data = (kpiInfo?.kpi?.data) ? (
    modifyData(
      kpiInfo?.kpi?.meta?.filterValues,
      kpiInfo?.kpi?.meta?.groupValues,
      kpiInfo?.kpi.data,
      kpiInfo?.kpi?.meta?.lines?.[0]?.column,
    )
  ) : (
    { index: [], data: [], columns: [] }
  );

  return (
    <MainContainer>
      {/* Fechar e comentarios */}
      <ActionButtons className="top-right">
        {kpiInfo?.kpi?.comment?.trim()?.length > 0 && (
          <Popover
            open={showComment}
            closePopover={closeComment}
            style={{
              borderTopRightRadius: '0',
              zIndex: 1050,
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'none',
            }}
            offset={[-30, 2]}
          >
            <Popover.Action>
              <Tooltip text="Comentário" atModal direction="bottom-end">
                <IconButton
                  size="large"
                  color="white"
                  style={{ boxShadow: 'none', padding: '5px' }}
                  onClick={openComment}
                >
                  <CommentExclamation />
                </IconButton>
              </Tooltip>
            </Popover.Action>
            <Popover.Content>
              <CommentArea>
                {kpiInfo?.kpi?.comment}
              </CommentArea>
            </Popover.Content>
          </Popover>
        )}
        <Tooltip text="Fechar" atModal direction="bottom-end">
          <IconButton
            size="large"
            color="white"
            style={{ boxShadow: 'none', padding: '5px' }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </ActionButtons>

      {/* Kpi anterior */}
      <ActionButtons className="middle-left">
        <Tooltip text="Kpi anterior" atModal direction="right">
          <IconButton
            size="large"
            color="white"
            style={{ boxShadow: 'none', padding: '5px' }}
            onClick={prevKpi}
          >
            <ArrowRight />
          </IconButton>
        </Tooltip>
      </ActionButtons>

      {/* Proximo kpi */}
      <ActionButtons className="middle-right">
        <Tooltip text="Próximo kpi" atModal direction="left">
          <IconButton
            size="large"
            color="white"
            style={{ boxShadow: 'none', padding: '5px' }}
            onClick={nextKpi}
          >
            <ArrowRight />
          </IconButton>
        </Tooltip>
      </ActionButtons>

      {/* Controles de zoom */}
      {!['Table', 'label', 'smartLabel', 'Value'].includes(kpiInfo?.kpi?.type) && (
        <>
          <ActionButtons className="bottom-center">
            <Tooltip text="Reduzir" atModal direction="top">
              <IconButton
                size="large"
                color="white"
                style={{ boxShadow: 'none', padding: '5px' }}
                onClick={decreaseZoom}
              >
                <Minus />
              </IconButton>
            </Tooltip>
            <Tooltip text="Redefinir zoom" atModal direction="top">
              <IconButton
                size="medium"
                color="white"
                shape="rounded"
                style={{ boxShadow: 'none', padding: '5px', gap: '5px' }}
                onClick={resetZoom}
              >
                <Finder />
                <BtnText>{`${(zoomFactor * 100).toFixed(0)}%`}</BtnText>
              </IconButton>
            </Tooltip>
            <Tooltip text="Aumentar" atModal direction="top">
              <IconButton
                size="large"
                color="white"
                style={{ boxShadow: 'none', padding: '5px' }}
                onClick={increaseZoom}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </ActionButtons>
          {/* Controle de tipo de ajuste */}
          <ActionButtons className="bottom-left">
            <Tooltip text={fillScreen ? 'Ajuste proporcional' : 'Preencher tela'} atModal direction="top-end">
              <IconButton
                size="large"
                color="white"
                className="fix-icon"
                style={{ boxShadow: 'none', padding: '5px' }}
                onClick={toggleAdjust}
              >
                {fillScreen ? <AdjustScreen /> : <FillScreen />}
              </IconButton>
            </Tooltip>
          </ActionButtons>
        </>
      )}

      {/* Kpi */}
      <KpiContainer
        ref={containerRef}
        onMouseDown={mouseDownHandle}
        onMouseMove={mouseMoveHandle}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
        isDragging={isDragging.current}
        allowDrag={!['Table', 'label', 'smartLabel', 'Value'].includes(kpiInfo?.kpi?.type)}
      >
        <KpiCard
          size={resizeKpi()}
          style={getCardStyle(cardStyles || {})}
          kpiType={kpiInfo?.kpi?.style?.type ?? kpiInfo?.kpi?.type}
          titleFontSize={
            kpiInfo?.kpi?.style?.ShowTitleControl?.checked
              ? kpiInfo?.kpi?.style?.StyleTitleControl?.fontSize : 0
          }
          showTitle={kpiInfo?.kpi?.style?.ShowTitleControl?.checked}
        >
          {['label', 'smartLabel'].includes(kpiInfo?.kpi?.type) && (
            <InsightCard
              item={kpiInfo.kpi}
              globalTheme={globalTheme}
            />
          )}
          {kpiInfo?.kpi?.type === 'Iframe' && (
            <KpiStatus
              conditions={new Map([
                [
                  'invalidInStory',
                  data && isKpiInvalidInStory(kpiInfo?.kpi?.linkType) && collection !== 'kpis',
                ],
              ])}
            >
              <IframeCard item={kpiInfo.kpi} />
            </KpiStatus>
          )}
          {!['label', 'smartLabel', 'Iframe'].includes(kpiInfo?.kpi?.type) && (
            <>
              {kpiInfo?.kpi?.style?.ShowTitleControl?.checked ? (
                <KpiTitle
                  style={getValueBackground(
                    (kpiInfo?.kpi?.style?.type ?? kpiInfo?.kpi?.type) === 'Value',
                    globalTheme,
                    kpiInfo?.kpi?.style?.GlobalValue?.background,
                  )}
                >
                  <span style={titleStyle(kpiInfo?.kpi?.style?.StyleTitleControl, globalTheme)}>
                    {kpiInfo?.kpi?.name}
                  </span>
                </KpiTitle>
              ) : <></>}
              <KpiWrapper>
                <KpiStatus
                  isLoading={isDataLoading}
                  conditions={new Map([
                    [
                      'invalidInStory',
                      data && isKpiInvalidInStory(kpiInfo?.kpi?.type) && collection !== 'kpis',
                    ],
                    [
                      'emptyDataSet',
                      !data?.data?.length > 0 && kpiInfo?.kpi?.type !== 'Value',
                    ],
                    [
                      'tooMuchCategories',
                      isLimitedKpi(kpiInfo?.kpi?.type) && (
                        data?.columns?.length > KPILIMIT || data?.index?.length > KPILIMIT
                      ),
                    ],
                  ])}
                >
                  <>
                    {Boolean(drilldownKeys) && (
                      <div style={{ marginLeft: '1rem' }}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={removeDrilldown}
                        >
                          <span style={{ fontSize: '12px' }}>
                            Voltar
                          </span>
                        </Button>
                      </div>
                    )}
                    <Chart
                      key={kpiInfo?.kpi?.id}
                      type={kpiInfo?.kpi?.type}
                      data={data}
                      handleDrilldownClick={
                        kpiInfo?.kpi?.meta?.drilldown?.length > 0
                        && !drilldownKeys ? handleDrildown : null
                      }
                      valueFunction={kpiInfo?.kpi?.meta?.values?.[0]?.map}
                      styleConfig={kpiInfo?.kpi?.type === 'Map' ? { ...kpiInfo?.kpi?.style, zoom: zoomFactor } : kpiInfo?.kpi?.style}
                      handleStyleConfig={() => {}}
                      isEdit={false}
                      globalTheme={globalTheme}
                    />
                  </>
                </KpiStatus>
              </KpiWrapper>
              {kpiInfo?.kpi?.type === 'SubsetTable' && (
                <PaginationWrapper bgColor={globalTheme?.backgroundColor ?? '#ffffff'}>
                  <TablePagination
                    page={page}
                    totalPages={(data?.total_pages ?? 1) - 1}
                    setPage={setPage}
                    useGoToPage={false}
                    label=""
                    className="tableWrapperPagination"
                    disabled={false}
                  />
                </PaginationWrapper>
              )}
            </>
          )}
        </KpiCard>
      </KpiContainer>
    </MainContainer>
  );
};

KpiViewer.propTypes = {
  /**
   * Funcao para fechar a exibicao do kpi
   */
  close: PropTypes.func,
  /**
   * Objeto com informacoes do kpi
   */
  kpiItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Lista das posicoes dos kpis no dashboard
   */
  layout: PropTypes.arrayOf(PropTypes.shape({
    i: PropTypes.string,
  })),
  /**
   * Funcao que obtem o tamanho do kpi na tela
   */
  getKpiSize: PropTypes.func,
  /**
   * Estilos do kpi selecionado
   */
  cardStyles: PropTypes.objectOf(PropTypes.shape({})),
  /**
   * Origem do kpi
   */
  collection: PropTypes.string,
  /**
   * Seleciona um outro kpi a partir do id
   */
  changeKpi: PropTypes.func,
  /**
   * Lida com a paginação de subtabelas
   */
  pageState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  ),
  /**
   * True se os dados estiverem carregando
   */
  isDataLoading: PropTypes.bool,

  /**
   * Chaves de drilldown
   */
  drilldownKeys: PropTypes.arrayOf(PropTypes.string),
  /**
   * Função para lidar com drilldown
   */
  handleDrildown: PropTypes.func,
  /**
   * Função para remover drilldown
   */
  removeDrilldown: PropTypes.func,
};

KpiViewer.defaultProps = {
  close: () => {},
  layout: undefined,
  getKpiSize: () => {},
  cardStyles: {},
  collection: 'kpis',
  changeKpi: () => {},
  pageState: [0, () => {}],
  isDataLoading: false,
  drilldownKeys: null,
  handleDrildown: null,
  removeDrilldown: null,
};

export default KpiViewer;
