/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { ResponsiveFunnel } from '@nivo/funnel';

import CustomLegend from '../../utils/chartTools/createLegend';
import colorSelector from '../../utils/formatCharts/handleColor';
import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';
import { dataSort, genData1D } from '../../utils/formatCharts/handleData';
// import { checkErrors } from '../../utils/chartTools/kpiMsg';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Funnel = ({
  data, styleConfig, isEdit, globalTheme, handleDrilldownClick,
}) => {
  const [chartData, setChartData] = useState({ ready: false });
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [hiddenIds, setHiddenIds] = useState([]);

  useEffect(() => {
    setChartData(genData1D(data, styleConfig, true));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig, true),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setFilteredChartData(chartData.data.filter((item) => !hiddenIds.includes(item.id)));
    }
  }, [chartData, hiddenIds]);

  const createLabel = (value) => customValueLabel(value, styleConfig);

  const createTooltip = (val) => customTooltip(val, 'funnel', styleConfig, handleDrilldownClick);
  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
        [
          'hasNegativeValues',
          chartData.hasNegative,
        ],
      ])}
    >
      <ResponsiveFunnel
        data={filteredChartData}
        /* GENERAL */
        margin={styleConfig?.Margin || {
          top: 22,
          right: 180,
          bottom: 22,
          left: 180,
        }}
        onClick={handleDrilldownClick ? ((dataPoint) => {
          handleDrilldownClick(dataPoint?.data?.id);
        }) : null}
        shapeBlending={styleConfig?.BodyRadiusControl?.value}
        spacing={styleConfig?.CategorySpacingControl?.value}
        valueFormat={createLabel}
        direction={styleConfig.LayoutControl ? styleConfig.LayoutControl : 'vertical'}
        interpolation={styleConfig.InterpolationControl ? styleConfig.InterpolationControl : 'smooth'}
        /* BORDER */
        borderWidth={styleConfig?.BorderWidthControl?.value}
        borderColor={
          styleConfig.BorderColorControl
            ? styleConfig.BorderColorControl
            : { from: 'color', modifiers: [['darker', 1.6]] }
        }
        /* STYLES/THEMES */
        colors={colorSelector({
          dataLength: chartData.data.length,
          config: styleConfig,
          colorKey: 'id',
          globalTheme,
        })}
        theme={{
          labels: {
            text: {
              fontSize: parseInt(styleConfig?.LabelsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LabelsFontFamilyControl?.value}', Arial`,
              transform: `translate(${styleConfig?.LabelTranslateX ? styleConfig?.LabelTranslateX : 0}px, ${styleConfig?.LabelTranslateY ? styleConfig?.LabelTranslateY : 0}px) rotate(${styleConfig?.LabelRotationControl ? styleConfig?.LabelRotationControl : 0}deg)`,
              transformOrigin: 'center center',
              transformBox: 'fill-box',
            },
          },
          legends: {
            text: {
              fontSize: parseInt(styleConfig?.LegendsFontSizeControl?.value, 10),
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
        }}
        /* LABELS */
        enableLabel={styleConfig?.EnableLabelControl?.checked}
        labelColor={styleConfig?.LabelTextColorControl}
        /* LEGEND */
        layers={['separators', 'parts', 'labels', 'annotations', (dt) => (
          styleConfig?.LegendControl?.checked ? (
            <CustomLegend
              chartDataAr={chartData.data}
              colorParts={dt.parts.reduce((aux, p) => {
                aux[p.data.id] = p.color;
                return aux;
              }, {})}
              sizes={{
                width: dt.width,
                height: dt.height,
              }}
              config={styleConfig}
              setToggleIds={setHiddenIds}
              isEdit={isEdit}
            />
          ) : <></>
        )]}
        /* TOOLTIPS */
        isInteractive={styleConfig?.InteractiveControl}
        tooltip={createTooltip}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
        currentPartSizeExtension={styleConfig.InteractiveControl && !!styleConfig.Animate
          ? (+styleConfig.PartExtension || 0) : 0}
        beforeSeparatorLength={20}
        afterSeparatorLength={20}
      />
    </KpiStatus>
  ) : <></>;
};

Funnel.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
  handleDrilldownClick: PropTypes.func,
};

Funnel.defaultProps = {
  globalTheme: {},
  handleDrilldownClick: null,
};

export default Funnel;
